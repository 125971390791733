import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="Page Not found" />
      <h2 className="w-full text-center text-3xl font-bold inline-block my-16 p-4">
        Page not found
      </h2>
    </Layout>
  )
}

export default NotFoundPage
